import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

function Randomizer(props) {
  const millsAds = [
    {
      image: '/ads/Challenger.jpg',
      link: 'https://www.challengerschool.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=PioneerTheatreCompany',
      alt: 'Challenger School',
      class: 'webFormat',
    },
    {
      image: '/ads/lavahotsprings.jpg',
      link: 'https://www.lavahotsprings.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=PioneerTheatreCompany',
      alt: 'Lava Hot Springs',
      class: 'webFormat',
    },
    {
      image: '/ads/tuacahn.jpg',
      link: 'https://www.tuacahn.org/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=PioneerTheatreCompany',
      alt: 'Tuacahn',
      class: 'webFormat',
    },
    {
      image: '/ads/Hamilton.jpg',
      link: 'https://www.hamiltonparkinteriors.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=PioneerTheatreCompany',
      alt: 'Hamilton Park Interiors',
      class: 'webFormat',
    },
    {
      image: '/ads/RCWilley.jpg',
      link: 'https://www.rcwilley.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=PioneerTheatreCompany',
      alt: 'RC Willey',
      class: 'webFormat',
    },
    {
      image: '/ads/ufo.jpg',
      link: 'https://www.utahfestival.org/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=PioneerTheatreCompany',
      alt: 'RC Willey',
      class: 'webFormat',
    },
    {
      image: '/ads/UniversityCU.jpg',
      link: 'https://www.ucreditu.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=PioneerTheatreCompany',
      alt: 'University of Utah Credit Union',
      class: 'webFormat',
    },
    {
      image: '/ads/shakespeare.jpg',
      link: 'https://www.bard.org/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=PioneerTheatreCompany',
      alt: 'Utah Shakespeare Festival',
      class: 'webFormat',
    },
    {
      image: '/ads/adibs.jpg',
      link: 'https://www.adibs.com/?utm_source=Mills%20Publishing&utm_medium=Digital%20playbill&utm_campaign=PioneerTheatreCompany',
      alt: 'Adibs Rug Gallery',
      class: 'webFormat',
    },
  ];

  const [randomAd] = React.useState(
    millsAds[Math.floor(Math.random() * millsAds.length)]
  );

  return (
    <>
      <br />
      <OutboundLink
        href={randomAd.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={randomAd.image}
          alt={randomAd.alt}
          className={randomAd.class}
        />
      </OutboundLink>
      <hr />
      <br />
    </>
  );
}

export default Randomizer;
